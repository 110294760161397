<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo" @click="$router.push('/')">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        HWA
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Email Verification 🕵🏻‍♀️
        </h2>
        <p class="mb-2">
          Oops! 😖 You have not verified your email, click on the button to resend link.
        </p>

        <b-button
          :disabled="loading"
          variant="primary"
          class="mb-2 btn-sm-block"
          @click="resendLink()"
        >
          <b-spinner
            v-if="loading"
            small
            type="grow"
          />
          {{ loading?'Loading...':'Resend Verification Link' }}
        </b-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BButton, BImg, BSpinner,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store'

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  methods: {
    resendLink() {
      this.loading = true
      this.$http
        .post('/api/auth/resend_link')
        .then(res => {
          this.$bvToast.toast('Email verification link sent Successfully', {
            title: 'Verification',
            variant: 'success',
            solid: true,
          })
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';
</style>
